<template>
  <v-col
    :aut-panel="`${name}_panel`"
    :cols="effectiveWidth ? 12 : 0"
    :sm="effectiveWidth"
    v-show="effectiveWidth"
    v-bind="attributes"
    :class="isPanel ? 'behaviour_panel' : ''"
  >
    <slot></slot>
    <draggable
      class="row--dense fill-height"
      handle=".dragger"
      group="pinnedPages"
      v-model="pages"
    >
      <DynamicPage
        v-for="(pinnedPage, i) in pages"
        :key="`${i}-panel`"
        permission="pin_pages"
        :aut-dynamic-page="pinnedPage.href"
        :definition="pinnedPage"
        class="mb-n1 behavior_dynamic_page"
        :class="isPanel ? featureClass : ''"
      />
    </draggable>
  </v-col>
</template>
<script>
import { isSamePageWildCard } from "@/util";
const debug = require("debug")("atman.dynamic_pages");

import draggable from "vuedraggable";
import { mapActions } from "vuex";
export default {
  name: "DynamicPages",
  props: {
    name: {
      type: String,
    },
    width: {
      type: Number,
    },
    attributes: {
      type: Object,
    },
    editMode: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    $route() {
      debug(`$route changed. Will check width`);
      this.checkWidth();
    },
    effectiveWidth() {
      this.checkWidth();
    },
  },
  computed: {
    targetPanel() {
      let name = this.name ? `/${this.name}_panel` : "/dashboard";
      return name;
    },
    isPanel() {
      return ["left", "right"].includes(this.name);
    },
    pages: {
      get() {
        let result = [];
        let pagesPinnedToDomain =
          this.$store.getters["pagesPinnedToDomain"](this.targetPanel) || [];
        debug(`pagesPinnedTo ${this.targetPanel}`, pagesPinnedToDomain);
        let pagesPinnedToPreferences =
          this.$store.getters["preferences/pinnedPages"](this.targetPanel) ||
          [];
        result = [...pagesPinnedToDomain, ...pagesPinnedToPreferences];

        if (!this.isFeatureEnabled("dynamic_pages.allow_target")) {
          return result;
        }
        let current_path = this.$route.path;
        const page = this.$route.query?.page;
        if (page) {
          current_path = `${current_path}?page=${page}`;
        }
        const pin_pages = result.filter(
          (page) =>
            !page?.pages?.length ||
            page?.pages?.some((pagePath) =>
              isSamePageWildCard(pagePath, current_path)
            )
        );
        return pin_pages;
      },
      set(pinnedList) {
        const newList = pinnedList.map((page) => {
          page.target = this.targetPanel;
          debug(`Page Target`, page);
          return page;
        });
        this.updatePinnedList({
          url: this.targetPanel,
          pinnedList: newList,
        });
      },
    },
    featureClass() {
      return this.isFeatureEnabled("panel_style")
        ? "behaviour_panel_with_background"
        : "";
    },
    effectiveWidth() {
      const pagesAvailable = this.pages?.length;

      if (this.editMode) {
        return this.width;
      }

      if (!pagesAvailable) {
        return 0;
      }

      return this.width;
    },
  },
  components: {
    draggable,
    DynamicPage: () => import("@/components/Page/DynamicPage"),
  },
  mounted() {
    this.applySticky();
    this.checkWidth();
  },
  methods: {
    checkWidth() {
      this.$nextTick(() => {
        debug(
          `width is ${this.effectiveWidth}. Will ${
            this.effectiveWidth > 0 ? "not" : ""
          } emit collapse_panel`
        );
        if (this.effectiveWidth == 0) {
          this.$emit("collapse_panel");
        }
      });
    },
    ...mapActions(["updatePinnedList"]),
    applySticky() {
      if (!this.isPanel) {
        return;
      }
      if (
        this.isFeatureEnabled(`layout.${this.targetPanel}.sticky`) &&
        !this.isSmallScreen
      ) {
        this.$el.classList?.add("behaviour_sticky_panel");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.behaviour_panel {
  /* make the text in panels slightly smaller than  */
  font-size: small !important;

  /* Make a panel sticky */
  &.behaviour_sticky_panel {
    .behaviour_dynamic_page:last-child {
      position: sticky;
      top: var(--header-height, 0px);
      min-height: 81vh;
      height: 81vh;
    }
  }

  ::v-deep {
    .behavior_list_item_container {
      .v-list-item {
        padding-right: 0px !important;
        padding-left: 0px !important;
        min-height: 0px !important;
      }
      .v-list-item__content {
        padding: 2px 0 !important;
      }
    }
    .behaviour_component_title {
      padding-top: 4px;
      margin-bottom: 0px;
      font-size: 1px;
      /*  border-bottom: solid 0.5em;
      border-color: map-get($grey, "lighten-2"); //removing border to keep consistency with middle panel*/
      p {
        margin-bottom: 0px;
        &.text-h6 {
          font-size: 1.15rem !important;
        }
      }
    }
  }

  .behaviour_panel_with_background {
    ::v-deep {
      .behaviour_atman_component {
        background-color: var(--v-primary-base);
        .behaviour_list_table {
          background: transparent;
        }
      }
    }
  }
}
</style>
